<template>
  <div class="container is-fluid">
    <div class="columns is-centered">
      <div class="column is-two-thirds is-4">
        <b-select v-model="type" expanded placeholder="Seleccione el tipo de fecha a consultar">
          <option v-for="type in types" :value="type.value" :key="type.value">
            {{ type.text }}
          </option>
        </b-select>
        <b-field label="Seleccione el rango de fechas a consultar">
          <b-datepicker
            v-model="dates"
            show-week-number
            nearby-selectable-month-days
            :rules-for-first-week="rfw"
            :first-day-of-week="fdw"
            :max-date="maxDate"
            inline
            range
          />
        </b-field>
      </div>
      <div class="column is-one-third is-4 is-centered">
        Se descargarán los datos entre las siguientes fechas: <br />
        {{ localDates }} <br />
        <b-tooltip :label="button_message" type="is-secondary is-light" position="is-right">
          <b-button icon-left="download" @click="download" :disabled="buttonDisable">Descargar</b-button>
        </b-tooltip>
      </div>
    </div>
    <b-modal v-model="downloadControl" :can-cancel="false">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Descargando...</p>
          <br />
          <p class="modal-card-subtitle">Por favor espere...</p>
        </header>
        <section class="modal-card-body">
          Recopilando datos desde Fracttal, esto puede tardar un poco... <br /><br />
          <b-progress type="is-success" animated indeterminate />
        </section>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "FracttalQuery",
  data() {
    const today = new Date();
    return {
      rfw: 1,
      fdw: 1,
      types: [
        { text: "Fecha de reporte", value: "initial_date" },
        { text: "Fecha programada", value: "date_maintenance" },
      ],
      type: undefined,
      dates: null,
      downloadControl: false,
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    };
  },
  computed: {
    buttonDisable() {
      if (!this.dates) {
        return true;
      }
      if (this.type === undefined) {
        return true;
      }
      if (this.downloadControl) {
        return true;
      }
      return false;
    },
    button_message() {
      if (this.type === undefined) {
        return "Seleccione el tipo de fecha a consultar";
      }
      if (!this.dates) {
        return "Seleccione un rango de fechas";
      }
      if (this.downloadControl) {
        return "Descarga en progreso";
      }
      return "Presione para descargar archivo";
    },
    localDates() {
      let dates = this.dates;
      if (dates) {
        dates = dates.map((date) => {
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          let day = date.getDate();
          return `${year}-${month.toString().padStart(2, "00")}-${day.toString().padStart(2, "00")}`;
        });
      }
      return dates;
    },
  },
  methods: {
    download() {
      console.log(this.localDates);
      this.downloadControl = true;
      let query = `?type=${this.type}&since=${this.localDates[0]}&until=${this.localDates[1]}&planta=PAICO`;
      fetch("https://ariztock.apidev.info/api/queryfracttal" + query, {
        method: "GET",
        // mode: "no-cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          console.log("file obtained");
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "horas.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          this.downloadControl = false;
        });
    },
  },
};
</script>
