<template>
  <div id="app">
    <b-navbar type="is-warning" fixed-top :mobile-burger="false">
      <template #brand>
        <b-navbar-item :to="{ path: '/' }">
          <img :src="require('@/assets/logo-letras_negras.png')" />
        </b-navbar-item>
      </template>
    </b-navbar>
    <FracttalQuery />
  </div>
</template>

<script>
import FracttalQuery from "./components/FracttalQuery.vue";

export default {
  name: "App",
  components: {
    FracttalQuery,
  },
  mounted() {
    document.title = "Horas Fracttal";
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 5%;
  text-align: left;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
